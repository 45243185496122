export default function GeopsIcon({ className, white }) {
  const primaryColor = white ? "text-white" : "text-green";
  const secondaryColor = white ? "text-white" : "text-blue";
  return (
    <svg viewBox="0 0 200 50" className={className}>
      <path
        className={`fill-current ${secondaryColor}`}
        d="M95.21 22.7a2.98 2.98 0 012.97 2.97 2.98 2.98 0 01-2.97 2.96 2.98 2.98 0 01-2.96-2.96 2.98 2.98 0 012.96-2.97z"
      />
      <path
        className={`fill-current ${primaryColor}`}
        d="M100.27 34.44c9.28.28 16.89-9.61 14.22-18.5-2.09-8.06-11.63-13.26-19.52-9.95C87.1 8.86 83 19.06 87.17 26.45a14.84 14.84 0 0013.1 7.99zm-.55 4.94c-11.22.05-20.72-11.05-19-22.13 1.14-10.76 12.32-19.07 23-16.9 10.45 1.73 18.25 12.86 15.82 23.31-1.76 9.05-10.52 16.19-19.82 15.72z"
      />
      <path
        className={`fill-current ${secondaryColor}`}
        d="M28 7.5H14.08c-6 0-10.87 4.13-10.87 9.22v11.3c0 5.08 4.88 9.21 10.87 9.21h20.54v3.55c0 2.75-2.98 4.99-6.64 4.99H5.72v.02l-.19-.02c-1.15 0-2.1.95-2.1 2.11s.95 2.1 2.1 2.1l.19-.01V50h22.27c6 0 10.87-4.14 10.87-9.22V16.72c0-5.1-4.88-9.23-10.87-9.23zM34.62 33H14.09c-3.66 0-6.64-2.23-6.64-4.99v-11.3c0-2.75 2.98-4.99 6.64-4.99h13.9c3.66 0 6.63 2.24 6.63 5V33z"
      />
      <path
        className={`fill-current ${secondaryColor}`}
        d="M77.73 24.5v-7.78c0-5.09-4.87-9.23-10.87-9.23h-13.9c-6 0-10.87 4.14-10.87 9.23v11.3c0 5.08 4.87 9.21 10.87 9.21h22.68c1.16 0 2.12-.95 2.12-2.11S76.8 33 75.64 33H52.96c-3.66 0-6.64-2.24-6.64-4.99v-3.53l31.4.03zM52.96 11.73h13.9c3.66 0 6.64 2.24 6.64 5v3.54H46.32v-3.54c0-2.76 2.98-5 6.64-5z"
      />
      <path
        className={`fill-current ${secondaryColor}`}
        d="M147.44 7.5h-13.9c-6 0-10.87 4.13-10.87 9.22v30.8c0 1.17.96 2.13 2.12 2.13 1.16 0 2.11-.96 2.11-2.12v-10.3h20.54c6 0 10.87-4.13 10.87-9.22v-11.3c0-5.08-4.88-9.22-10.87-9.22zm-20.54 9.22c0-2.76 2.98-5 6.64-5h13.9c3.66 0 6.64 2.24 6.64 5v11.3c0 2.75-2.98 4.98-6.64 4.98H126.9V16.72z"
      />
      <path
        className={`fill-current ${secondaryColor}`}
        d="M188.7 20.17l-.15-.02h-19.04a4.24 4.24 0 01-4.22-4.22c0-2.3 1.87-4.19 4.16-4.22h25.14c1.16 0 2.11-.96 2.11-2.12a2.13 2.13 0 00-2.18-2.11H169.48a8.5 8.5 0 00-8.45 8.45 8.5 8.5 0 008.42 8.45H188.39c2.3.09 4.14 2 4.14 4.3 0 2.35-1.94 4.3-4.3 4.3h-.05l-25-.01h-.03c-1.16 0-2.11.95-2.11 2.11s.95 2.12 2.11 2.12H187.84l.08-.01h.11l.22.01h.01a8.56 8.56 0 008.52-8.52 8.56 8.56 0 00-8.08-8.51z"
      />
    </svg>
  );
}
